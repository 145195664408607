<template>
	<div
		:id="blockId"
		class="block-product-list-wrapper"
		:style="computedStyles"
	>
		<ListSkeletonLoader
			v-if="isLoading"
			:column-count="columnCount"
		/>
		<div
			v-else-if="isProductListShown"
			ref="productList"
			class="block-product-list"
		>
			<div class="block-product-list__content">
				<RouterLink
					v-for="(product, index) in currentPageProducts"
					:key="`product-${index}-${product.id}`"
					:to="{ path: getItemProductPagePath(product.id) }"
					class="block-product-list__link"
				>
					<ProductListItem
						:image="product.thumbnail"
						:title="product.title"
						:price="product.variants[0].prices[0]"
						:is-centered="isCentered"
						:is-eager="isEager && index === 0"
						:duration="getFormattedBookingDuration(product, translations)"
						:image-max-width="imageWidth"
						:is-store-quantity-tracked="product.variants[0].manage_inventory"
						:quantity="getFullProductQuantity(product)"
						:product-type="product.type.value"
						:translations="translations"
					/>
				</RouterLink>
			</div>
			<ZyroPagination
				:current-page="currentPage"
				:page-count="pageCount"
				class="block-product-list__pagination"
				color="var(--body-color)"
				@change-page="handlePageChange($event)"
			/>
		</div>
		<ProductListEmptyState
			v-else
			:text-color-vars="textColorVars"
		/>
	</div>
</template>

<script>
import ListSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ListSkeletonLoader.vue';
import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import ProductListEmptyState from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListEmptyState.vue';
import ProductListItem from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListItem.vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH } from '@zyro-inc/site-modules/constants/defaultStyles';
import { getFormattedBookingDuration } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';

import { defineComponent } from 'vue';
import { mapState } from 'vuex';

const GAP_SIZE = 24;

export default defineComponent({
	components: {
		ProductListItem,
		ProductListEmptyState,
		ZyroPagination,
		ListSkeletonLoader,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		products: {
			type: Array,
			default: () => ([]),
		},
		productPages: {
			type: Object,
			default: () => ({}),
		},
		blockStyle: {
			type: Object,
			default: () => ({}),
		},
		textColorVars: {
			type: Object,
			default: () => ({}),
		},
		isProductListShown: {
			type: Boolean,
			default: true,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isEager: {
			type: Boolean,
			default: false,
		},
		columnCount: {
			type: Number,
			default: 3,
		},
		productsPerPage: {
			type: Number,
			default: 9,
		},
		translations: {
			type: Object,
			default: () => ({}),
		},
		productIds: {
			type: Array,
			default: () => ([]),
		},
		productCategoryId: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			currentPage: Number.parseInt(this.$route.query['store-page'], 10) || 1,
		};
	},

	computed: {
		...mapState('ecommerce', ['variantsQuantity']),
		isCentered() {
			return this.blockStyle?.textAlign === 'center';
		},
		imageWidth() {
			const totalGapsWidth = (this.columnCount - 1) * GAP_SIZE;
			const listWidth = DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH - totalGapsWidth;

			return Math.floor(listWidth / this.columnCount);
		},
		computedStyles() {
			return {
				'--image-max-width': `${this.imageWidth}px`,
				'--gap-size': `${GAP_SIZE}px`,
				...objectToCssVariables(this.textColorVars),
			};
		},
		pageCount() {
			return Math.ceil(this.productIds.length / this.productsPerPage);
		},
		currentPageProducts() {
			const modifiedCurrentPage = this.currentPage > this.pageCount || this.currentPage < 1 ? 1 : this.currentPage;
			const startIndex = (modifiedCurrentPage - 1) * this.productsPerPage;
			const endIndex = startIndex + this.productsPerPage;
			const productValues = Object.values(this.products);
			const targetIds = this.productIds.slice(startIndex, endIndex);
			const displayedProducts = productValues.filter((product) => targetIds.includes(product.id));

			return displayedProducts.length ? displayedProducts : productValues.slice(startIndex, endIndex);
		},
	},

	methods: {
		getFormattedBookingDuration,
		handlePageChange(page) {
			this.$emit('page-changed', page);
			this.currentPage = page;
			this.$router.push({
				hash: this.blockId,
				query: {
					'store-page': page,
				},
			});
		},
		getItemProductPagePath(productId) {
			const productPage = Object.values(this.productPages).find((page) => page.productId === productId);

			if (!productPage) {
				return '/';
			}

			return `/${productPage.slug}`;
		},
		getFullProductQuantity(product) {
			return product.variants.reduce((acc, curr) => acc
			+ this.variantsQuantity.find((variant) => variant.id === curr.id)?.inventory_quantity, 0);
		},
	},
});
</script>

<style lang="scss" scoped>
@mixin product-list-mobile {
	.block-product-list-wrapper {
		padding: var(--m-block-padding);
	}

	.block-product-list {
		&__content {
			grid-template-columns: 1fr;
		}
	}
}

.block-product-list-wrapper {
	z-index: $z-index-user--block-grid;
	display: flex;
	justify-content: center;
	padding: var(--block-padding);
}

.block-product-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__link {
		text-decoration: none;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(var(--image-max-width), 1fr));
		gap: var(--gap-size);
		width: 100%;
		max-width: var(--content-width);
	}

	&__pagination {
		margin-top: 16px;
	}
}

.zyro-mb-preview {
	@include product-list-mobile;
}

@media screen and (max-width: 700px) {
	@include product-list-mobile;
}

</style>
